<template>
  <v-container fluid>
  <p>From time to time we send customers updates about new additions to our range of products, or information that might be relevant for your business. You can customise which updates you want to receive</p>
  <v-card>
    <v-card-title>Subscribe to the following updates</v-card-title>
    <v-card-text>
    <v-switch
      hide-details
      color="success"
      v-model="preferences.subscriptions.productNews"
      label="New product types and merchandise"
    ></v-switch>
    <v-switch
      class="mt-0"
      hide-details
      color="success"
      v-model="preferences.subscriptions.artistNews"
      label="New artists and artworks"
    ></v-switch>
    <v-switch
      class="mt-0"
      hide-details
      color="success"
      v-model="preferences.subscriptions.serviceNews"
      label="Our newsletter about business developments and industry news"
    ></v-switch>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" class="mr-4" @click.native="saveSubscriptions()">
        Update Subscriptions
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-container>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  components: {
  },
  async created () {
    this.preferences = this.$store.getters['preferences']
  },
  async mounted () {
  },
  methods: {
    saveSubscriptions () {
      this.$store.dispatch('updateUser', { preferences: this.preferences })
    }
  },
  data: function () {
    return {
      preferences: null
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
